import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RegisterComponent from '../components/registerComponent'


class RegisterPage extends React.Component {

  render() {
    if (isLoggedIn()) {
      navigate(`/dashboard`)
    }

    return (
      <Layout title={'Register'} subtitle={'HRBC'}>
        <section className="page-section about-heading" style={{ paddingTop: '5rem' }}>
          <Container>
            <div className="about-heading-content">
              <Row>
                <Col xl={9} lg={10} className="mx-auto">
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading text-center mb-4">
                      <span className="section-heading-lower">Register</span>
                    </h2>
                    <Row>
                      <Col md={6} className="mx-auto">
                        <RegisterComponent />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default RegisterPage