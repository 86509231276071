import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Please enter your first name'),
    lastName: Yup.string()
        .required('Please enter your last name'),
    email: Yup.string()
        .email('The email\'s format is invalid')
        .required('Please enter your email'),
    username: Yup.string()
        .required('Please choose a username'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Please enter a password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
});  