import React, { useState } from 'react'
import { navigate, Link } from 'gatsby'
import queryString from 'query-string'
import { Formik } from 'formik'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { registerSchema } from './registerSchema'
import { registerUser } from '../services/auth'


function RegisterComponent() {
  let customErrors = { email: ``, username: `` }
  let emailInput, usernameInput, firstNameInput


  // save user role radio buttons separately as they are always valid
  const [userRole, setUserRole] = useState('member')


  // call method to create user and handle the response appropriately
  const handleRegisterUser = (data,) => {
    registerUser({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      username: data.username,
      password: data.password,
      role: data.userRole
    }).then(res => {
      if (res.success) {
        navigate(`/dashboard`)
      } else {
        firstNameInput.focus()  // in order to focus the actual input (email or usrname) in order to get the error styling to actuall appear
        customErrors = {
          email: ``,
          username: ``
        }
        if (typeof res.message === 'object') {
          res.message[0].messages.forEach(err => {
            if (err.id.match(/Auth.form.error.email/g)) {
              customErrors.email = err.message
              emailInput.focus()
            }
            if (err.id.match(/Auth.form.error.username/g)) {
              customErrors.username = err.message
              usernameInput.focus()
            }
          })
          // if (customErrors.email) emailInput.focus()
          // if (customErrors.username) usernameInput.focus()
        } else if (typeof res.message === 'string') {
          console.error(res.message)
        }
      }
      // console.warn('custom errors', customErrors)
    })
  }


  // handle and save radio button selection
  const handleRadioChange = event => {
    setUserRole(event.target.id)
  }


  // allow prepopulation of fields via url parameters

  const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

  const firstNameParam = parsed.f ? decodeURIComponent(parsed.f) : ``
  const lastNameParam = parsed.l ? decodeURIComponent(parsed.l) : ``
  const emailParam = parsed.e ? decodeURIComponent(parsed.e) : ``


  // load initial values for the form
  const initialValues = {
    firstName: firstNameParam,
    lastName: lastNameParam,
    email: emailParam,
    username: emailParam,
    userRole: userRole,
    password: ``,
    confirmPassword: ``
  }


  const handleCustomBlur = event => {
    document.querySelector('#username').value = event.currentTarget.value
  }


  return (
    <Formik
      validationSchema={registerSchema}
      initialValues={initialValues}
      onSubmit={data => { handleRegisterUser({ ...data, userRole: userRole }) }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label className="mb-0 form-label_small">First Name</Form.Label>
              <Form.Control
                ref={me => { firstNameInput = me }}
                name="firstName"
                className="text-input_plain fill-div"
                type="text"
                placeholder="Jonny"
                value={values.firstName}
                isInvalid={touched.firstName && errors.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label className="mb-0 form-label_small">Last Name</Form.Label>
              <Form.Control
                name="lastName"
                className="text-input_plain fill-div"
                type="text"
                placeholder="Appleseed"
                value={values.lastName}
                isInvalid={touched.lastName && errors.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label className="mb-0 form-label_small">Email</Form.Label>
              <Form.Control
                ref={(input => { emailInput = input })}
                name="email"
                className="text-input_plain fill-div"
                type="text"
                placeholder="jonny.appleseed@example.com"
                value={values.email}
                isInvalid={touched.email && errors.email || customErrors.email}
                onBlur={handleBlur, () => {
                  if (!values.username) {
                    values.username = values.email
                    usernameInput.value = values.email
                    // document.querySelector('#username').value = values.email
                  }
                }}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.email} {customErrors.email}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="username">
              <Form.Label className="mb-0 mr-1 form-label_small">Username</Form.Label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderUsernameTooltip}
              >
                <i className="fa fa-question-circle"></i>
              </OverlayTrigger>
              <Form.Control
                ref={input => { usernameInput = input }}
                name="username"
                className="text-input_plain fill-div"
                type="text"
                placeholder="jonny.appleseed@example.com"
                value={values.username}
                isInvalid={touched.username && errors.username || customErrors.username}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.username || customErrors.username}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="subject">
              <Form.Label className="mb-0 form-label_small mr-1">Desired Access Level</Form.Label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderAccessTooltip}
              >
                <i className="fa fa-question-circle"></i>
              </OverlayTrigger>
              <Form.Check
                type="radio"
                className="mb-0 px-0"
                name="subject-group"
                label="Member"
                id="member"
                checked={userRole === 'member'}
                onClick={handleRadioChange}
              />
              <Form.Check
                type="radio"
                className="mb-0 px-0"
                name="subject-group"
                label="Web Content Manager"
                id="manager"
                checked={userRole === 'manager'}
                onClick={handleRadioChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="password">
              <Form.Label className="mb-0 form-label_small">Create a Password</Form.Label>
              <Form.Control
                name="password"
                className="text-input_plain fill-div"
                type="password"
                placeholder="Password"
                value={values.password}
                isInvalid={touched.password && errors.password}
                isValid={touched.password && !errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Password meets security requirement</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="confirmPassword">
              {/* <Form.Label className="mb-0 form-label_small">Username</Form.Label> */}
              <Form.Control
                name="confirmPassword"
                className="text-input_plain fill-div"
                type="password"
                placeholder="Repeat password"
                value={values.confirmPassword}
                isInvalid={touched.confirmPassword && errors.confirmPassword}
                isValid={touched.confirmPassword && !errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Form.Control.Feedback>Password matches</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button className="mt-1" style={{ width: '100%' }} type="submit">Register</Button>
          <div className='text-center'>
            <Link to="/login" style={{ fontSize: '10pt' }}>Already a registered? Click here to login</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}


function renderUsernameTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>This is your identifier to login. Can be same as your email (recommended) or something easier for you to remember.</Tooltip>
  );
}


function renderAccessTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>"Member" access gives access to internal documents and will suffice for the majority of users</Tooltip>
  );
}


export default RegisterComponent